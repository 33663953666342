import './polyfills';

import { serializeError } from '@daxo/util/error.ts';
import * as Sentry from '@sentry/svelte';
import type { HandleClientError } from '@sveltejs/kit';

import { dev } from '$app/environment';

export const handleError: HandleClientError = ({ error, event }) => {
  console.error(error);

  const { route } = event;

  const errorId = crypto.randomUUID();

  Sentry.captureException(error, {
    tags: {
      routeId: route.id,
    },
    contexts: {
      error: {
        id: errorId,
      },
    },
  });

  return {
    message: 'Internal Server Error',
    errorId,
    detail: {
      error: dev ? serializeError(error) : undefined,
      routeId: route.id,
    },
  };
};
